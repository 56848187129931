import React from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
const { v4: uuidv4 } = require('uuid')

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import PhotoAndBio from '../../components/PhotoAndBio'

// App content
import TeamContent from '../../content/our-team'

// App utils
import { rhythm } from '../../utils/typography'
import { themeStyles, colors, presets, metrics } from '../../utils/theme'

import '../../styles/our-team.css'
class Page extends React.Component {
  render() {
    const teamIntro = TeamContent.TEAM_INTRO
    // Set image for each staff member and unique image id
    _.forEach(TeamContent.TEAM_MEMBERS, (e) => {
      e.image = this.props.data[e.photo].childImageSharp.gatsbyImageData
      e.image.id = uuidv4()
    })

    const admin_employee_elements = TeamContent.TEAM_MEMBERS.filter(
      (member) =>
        member.isOwner == false && member.title == 'Administrative Team'
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    const clinical_employee_elements = TeamContent.TEAM_MEMBERS.filter(
      (member) => member.isOwner == false && member.title == 'Clinical Team'
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    const ownerElements = TeamContent.TEAM_MEMBERS.filter(
      (member) => member.isOwner == true
    ).map((person) => <PhotoAndBio {...person} key={person.name} />)

    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title="OUR TEAM"
            bannerKey={'OUR_TEAM'}
            backgroundPosition="60% 50%"
            minHeight="360px"
          />
          <div
            css={[
              {
                [presets.Tablet]: { borderBottom: 0 },
                marginBottom: metrics.defaultMargin,
              },
              themeStyles.textPadding,
              {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: colors.brightBlue,
                textAlign: 'center',
              },
            ]}
          >
            <h1
              css={{
                letterSpacing: 10,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: 'white',
              }}
            >
              OUR TEAM
            </h1>
            <p
              css={[
                themeStyles.elementMargin,
                { marginTop: 0, color: 'white' },
              ]}
            >
              {teamIntro}
            </p>
          </div>
          <div
            css={{
              marginTop: rhythm(-1),
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {ownerElements}
          </div>
          <div>
            <h2
              css={{
                letterSpacing: 5,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: '#53D2E7',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              Administrative Team
            </h2>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {admin_employee_elements}
            </div>
          </div>
          <div>
            <h2
              css={{
                letterSpacing: 5,
                marginTop: rhythm(1 / 2),
                marginBottom: rhythm(1 / 2),
                color: '#53D2E7',
                textAlign: 'center',
                marginBottom: 20,
              }}
            >
              Clinical Team
            </h2>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {clinical_employee_elements}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

// Removed team members
// Chris: file(relativePath: { eq: "team/Chris.jpg" }) {...fluidImage400}
// Rosie: file(relativePath: { eq: "team/Rosie.jpg" }) {...fluidImage400}
// Khalid: file(relativePath: { eq: "team/Khalid.jpeg" }) {...fluidImage400}
// Emma: file(relativePath: { eq: "team/Emma.jpg" }) { ...fluidImage400}
// Paige: file(relativePath: { eq: "team/Paige.jpg" }) { ...fluidImage400}
// Shawna: file(relativePath: { eq: "team/Shawna.jpg" }) {...fluidImage400}
// Kortney: file(relativePath: { eq: "team/Kortney.jpg" }) { ...fluidImage400}
// Keanna: file(relativePath: { eq: "team/Keanna.jpg" }) {...fluidImage400}
// Tyra: file(relativePath: { eq: "team/Tyra.jpeg" }) {...fluidImage400}

export const pageQuery = graphql`
  query {
    Alyssa: file(relativePath: { eq: "team/Alyssa.jpg" }) {
      ...fluidImage400
    }
    Cathern: file(relativePath: { eq: "team/Cathern.jpg" }) {
      ...fluidImage400
    }
    Chelsea: file(relativePath: { eq: "team/Chelsea.jpeg" }) {
      ...fluidImage400
    }
    Danielle: file(relativePath: { eq: "team/Danielle.jpeg" }) {
      ...fluidImage400
    }
    Domico: file(relativePath: { eq: "team/Domico.jpg" }) {
      ...fluidImage400
    }
    DrCollins: file(relativePath: { eq: "team/DrCollins.jpg" }) {
      ...fluidImage400
    }
    Hannah: file(relativePath: { eq: "team/Hannah.jpg" }) {
      ...fluidImage400
    }
    Hope: file(relativePath: { eq: "team/Hope.jpeg" }) {
      ...fluidImage400
    }
    Jen: file(relativePath: { eq: "team/Jen.jpg" }) {
      ...fluidImage400
    }
    Jena: file(relativePath: { eq: "team/Jena.jpg" }) {
      ...fluidImage400
    }
    Jenna: file(relativePath: { eq: "team/Jenna.jpg" }) {
      ...fluidImage400
    }
    Jennifer: file(relativePath: { eq: "team/Jennifer.jpg" }) {
      ...fluidImage400
    }
    Kelsey: file(relativePath: { eq: "team/Kelsey.jpeg" }) {
      ...fluidImage400
    }
    Khalid: file(relativePath: { eq: "team/Khalid.jpeg" }) {
      ...fluidImage400
    }
    Kimberly: file(relativePath: { eq: "team/Kimberly.jpg" }) {
      ...fluidImage400
    }
    Lannie: file(relativePath: { eq: "team/Lannie.jpg" }) {
      ...fluidImage400
    }
    Laura: file(relativePath: { eq: "team/Laura.jpg" }) {
      ...fluidImage400
    }
    Litha: file(relativePath: { eq: "team/Litha.jpg" }) {
      ...fluidImage400
    }
    Martha: file(relativePath: { eq: "team/Martha.jpeg" }) {
      ...fluidImage400
    }
    Nuy: file(relativePath: { eq: "team/Nuy.jpg" }) {
      ...fluidImage400
    }
    Pone: file(relativePath: { eq: "team/Pone.jpg" }) {
      ...fluidImage400
    }
    Vanessa: file(relativePath: { eq: "team/Vanessa.jpg" }) {
      ...fluidImage400
    }
    Veronica: file(relativePath: { eq: "team/Veronica.jpg" }) {
      ...fluidImage400
    }
  }
`

export default Page
